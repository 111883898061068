import { icons } from 'components/icon/icons'

export const ALLOW_TO_OPEN_ATTENDANCE_POPUP = 'control-attendance-popup'
export const ALLOW_TO_OPEN_DRAWER = 'control-drawer'
export const KEEP_ROOM_OVERVIEW_OPEN_CLASS = 'MuiPickersCalendar-week'
export const ALLOW_TO_OPEN_MODAL = 'control-modal'
export const DEFAULT_SPACE_START_TIME = 360
export const DEFAULT_SPACE_END_TIME = 1080
export const DEFAULT_START_HOUR = 6
export const DEFAULT_END_HOUR = 18
export const DEFAULT_START_HOUR_ROOMS = 8
export const DEFAULT_START_MINS_ROOMS = 0
export const DEFAULT_END_HOUR_ROOMS = 9
export const DEFAULT_END_MINS_ROOMS = 0
export const DEFAULT_ROUND_MINUTES = 5
export const DEFAULT_MEETING_DURATION = 1
export const DEFAULT_INTERVAL_ROOM = 15
export const DEFAULT_INTERVAL_SPACE = 30
export const DEFAULT_OPTIONS_START_HOUR = 0
export const DEFAULT_OPTIONS_START_MINUTES = 0
export const DEFAULT_TIME_FORMAT = 'HH:mm'
export const OUTDATED_TIME_FORMAT = 'kk:mm'
export const DEFAULT_TIMESTAMP_INDEX = 60000
export const MILLISECONDS_PER_MINUTE = 60000
export const SECONDS_PER_MINUTE = 60
export const MINUTES_PER_HOUR = 60
export const STATUS_QUERY_INTERVAL_MINUTE = 1
export const LAST_TIME_PICKER_HOUR = 23

export const ORGANIZER = 'ORGANIZER'
export const FAVORITE_USERS = 'FAVORITE_USERS_ATTENDANCE_LIST'
export const BUILDING_USERS = 'BUILDING_USERS_ATTENDANCE_LIST'
export const OFFICE = 'OFFICE'
export const DESK = 'DESK'
export const SAME_BUILDING = 'ATTENDANCE_SAME_BUILDING'
export const OTHER_BUILDING = 'ATTENDANCE_OTHER_BUILDING'
export const VACATION = 'VACATION'
export const HOME = 'HOME'

export const InnerDrawerWidth = '200px'

export const innerDrawersIcons = [
  icons.CLOCK,
  icons.FLAG,
  icons.TWO_D_VIEW,
  icons.THREE_D_VIEW,
  icons.LOGOUT,
  icons.SETTINGS,
]

export const ROOM = 'ROOM'
export const SPACE = 'SPACE'

export const ASSET_STATUSES = {
  FREE: 'FREE',
  BOOKED: 'BOOKED',
  UNAVAILABLE_NON_BOOKABLE: 'UNAVAILABLE_NON_BOOKABLE',
  UNAVAILABLE_BLOCKED: 'UNAVAILABLE_BLOCKED',
  UNAVAILABLE: 'UNAVAILABLE',
  TAKE: 'TAKE',
  NONE: 'NONE',
  BOOK: 'BOOK',
  CLAIM: 'CLAIM',
  CLAIMED: 'CLAIMED',
  BUSY: 'BUSY',
  OFFLINE: 'OFFLINE',
}

export const MODE_MAP = {
  BOOK: 'ROOM',
  CLAIM: 'SPACE',
}

export const ASSET_TYPES = { SPACE: 'space', SIGN: 'sign', USER: 'user' }

export const ATTENDANCE_ICONS = {
  HOME: 'home',
  VACATION: 'vacation',
  ATTENDANCE_OTHER_BUILDING: 'other_building',
  ATTENDANCE_SAME_BUILDING: 'same_building',
}

export const typesMap = {
  VACATION: 'flowPlanner.offWork',
  HOME: 'flowPlanner.remote',
}
