import PropTypes from 'prop-types'
import { connect, useSelector, useDispatch } from 'react-redux'
import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Translate } from 'react-localize-redux'
import {
  format,
  set,
  getHours,
  getMinutes,
  endOfDay,
  startOfDay,
  isSameDay,
  isToday,
} from 'date-fns'

import TimePicker from 'containers/quickView/TimePicker'
import {
  timeOptions,
  checkIfToearlierThanFrom,
  getWorkingHour,
  getStartMinutesOfInterval,
  getEndOfDayTime,
} from 'utils/utilsFunctions'
import {
  getIsFuture,
  getDateFilter,
} from 'containers/searchPanel/filter/selectors'
import {
  getDefaultStart,
  getDefaultEnd,
  getCurrentFloorId,
} from 'containers/app/selectors'
import { getHasClaimOnBehalfPermission } from 'containers/quickView/spaceModal/claimFlow/selectors'
import {
  DEFAULT_SPACE_START_TIME,
  DEFAULT_SPACE_END_TIME,
  DEFAULT_INTERVAL_SPACE,
  DEFAULT_ROUND_MINUTES,
  DEFAULT_MEETING_DURATION,
  LAST_TIME_PICKER_HOUR,
} from 'utils/appVars'
import {
  setMapInterval,
  setDateFilter,
} from 'containers/searchPanel/filter/actions'
import Icon from 'components/Icon'
import { icons } from 'components/icon/icons'
import { getTimeFormat } from 'containers/mainPanel/timeFormats/selectors'

const ResetIcon = styled(Icon)`
  margin-right: 5px;
`

const ResetButton = styled.button`
  display: block;
  height: 35px;
  background-color: transparent;
  color: white;
  font-size: 13px;
  border: none;
  padding-right: 0;
  text-align: left;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const ResetContainer = styled.div`
  margin-bottom: 15px;
  margin-top: 5px;

  float: left;
`

const TimePickers = styled.div`
  display: flex;
  justify-content: space-around;
`
const TimePickerItem = styled.div`
  > span {
    display: inline-block;
    margin-bottom: 5px;
    font-size: 13px;
    color: ${(props) => props.theme.statisticsItem};
    font-weight: normal;
  }
`

const TimePickerTitle = styled.span`
  display: inline-block;
  margin-bottom: 5px;
  font-size: 14px;
  color: ${(props) => props.theme.statisticsItem};
  font-weight: 500;
  margin-left: 5px;
`
const TextSpan = styled.span`
  font-size: 22px;
`

const TimeSelectionRow = styled.div`
  height: 40px;
  > i {
    display: block;
    line-height: 40px;
    margin-right: 5px;
  }
  > p {
    margin: 0;
    line-height: 40px;
    font-size: 10px;
  }
`

const StyledTimePicker = styled(TimePicker)`
  & > div > div {
    width: 10rem;
    background-color: #373737;
    border: 1px solid #ffffff;
    div,
    svg {
      font-size: 13px !important;
      color: #ffffff;
      justify-content: center;
    }
  }
`

const TimePickerFilter = ({
  defaultStart = DEFAULT_SPACE_START_TIME,
  defaultEnd = DEFAULT_SPACE_END_TIME,
  currentFloorId,
  timeFormat,
}) => {
  const selectedDate = useSelector((state) => getDateFilter(state))
  const isFuture =
    useSelector((state) => getIsFuture(state)) &&
    !isSameDay(Date.now(), selectedDate)
  const intervalInMinutes = DEFAULT_INTERVAL_SPACE
  const dispatch = useDispatch()

  const currentDayRoundMinutes = DEFAULT_ROUND_MINUTES
  const currentMinute = getStartMinutesOfInterval(
    new Date().getMinutes(),
    currentDayRoundMinutes,
  )
  const currentHour = new Date().getHours()

  const startMinute = isFuture ? defaultStart % 60 : currentMinute

  const startHour = isFuture ? Math.trunc(defaultStart / 60) : currentHour

  const endHour = Math.trunc(defaultEnd / 60)

  const endMinute = defaultEnd % 60

  const defaultStartTime = getWorkingHour(
    startHour,
    startMinute,
    selectedDate,
    timeFormat,
  )
  const defaultEndTime = getWorkingHour(
    endHour,
    endMinute,
    selectedDate,
    timeFormat,
  )

  const defineToOptions = (from, manualSelect = false) => {
    setValueFrom(from)
    const fromDateObject = new Date(from.value)
    let to = selectedDate
    if (from.value.getHours() >= LAST_TIME_PICKER_HOUR) {
      to = getEndOfDayTime(fromDateObject)
    } else {
      to.setHours(
        fromDateObject.getHours() + DEFAULT_MEETING_DURATION,
        fromDateObject.getMinutes(),
      )
    }
    to = new Date(to)

    const toOptions = timeOptions(
      intervalInMinutes,
      selectedDate,
      from.value.getHours(),
      from.value.getMinutes(),
      timeFormat,
    )
    setТimeOptionsАvailableTo(toOptions.splice(1))
    setValueFromChanged(true)

    setToValue(
      getWorkingHour(to.getHours(), to.getMinutes(), to, timeFormat),
      from,
      manualSelect,
    )
  }
  const currentTimeOptions = timeOptions(
    intervalInMinutes,
    selectedDate,
    isFuture ? 0 : startHour,
    isFuture ? 0 : getStartMinutesOfInterval(startMinute, intervalInMinutes),
    timeFormat,
  )

  const [valueFrom, setValueFrom] = useState(defaultStartTime)
  const [valueTo, setValueTo] = useState(defaultEndTime)
  const [valueToChanged, setValueToChanged] = useState(false)
  const [valueFromChanged, setValueFromChanged] = useState(false)
  const [timeOptionsАvailableFrom, setТimeOptionsАvailableFrom] = useState(
    currentTimeOptions,
  )
  const [timeOptionsАvailableTo, setТimeOptionsАvailableTo] = useState(
    currentTimeOptions,
  )

  useEffect(() => {
    setТimeOptionsАvailableFrom(currentTimeOptions)
    setТimeOptionsАvailableTo(currentTimeOptions)
    defineToOptions(defaultStartTime)
    setToValue(defaultEndTime)
    setValueFromChanged(true)
    setValueToChanged(true)
    if (isToday(selectedDate)) {
      dispatch(setMapInterval({}))
      setValueFromChanged(false)
      setValueToChanged(false)
    }
  }, [selectedDate]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setТimeOptionsАvailableFrom(currentTimeOptions)
    setТimeOptionsАvailableTo(currentTimeOptions)
  }, [timeFormat]) // eslint-disable-line react-hooks/exhaustive-deps

  const setToValue = (
    newToTime,
    fromTime = valueFrom,
    manualSelect = false,
  ) => {
    const toDateAndTime = JSON.parse(JSON.stringify(newToTime))
    toDateAndTime.value = set(selectedDate, {
      hours: getHours(newToTime.value),
      minutes: getMinutes(newToTime.value),
    })
    setValueTo(toDateAndTime)
    setValueToChanged(true)

    dispatch(
      setMapInterval({
        start: isSameDay(fromTime.value, selectedDate)
          ? fromTime.value
          : defaultStartTime.value,
        end: newToTime.value,
        manualSelect: manualSelect,
      }),
    )
  }

  const resetInteval = () => {
    dispatch(setDateFilter(new Date()))
    dispatch(setMapInterval({}))
    setValueFromChanged(false)
    setValueToChanged(false)
  }
  return (
    <>
      <TimePickerTitle>
        <Translate id="filter.selectTime" />
      </TimePickerTitle>
      <TimePickers key={timeFormat}>
        <TimePickerItem>
          <span>
            <Translate id="flowPlanner.from" />
          </span>
          <TimeSelectionRow>
            <StyledTimePicker
              mapFilter
              value={
                valueFromChanged
                  ? valueFrom
                  : {
                      label: <TextSpan> - : - </TextSpan>,
                      value: null,
                    }
              }
              options={timeOptionsАvailableFrom}
              selectValue={(newToTime) => defineToOptions(newToTime, true)}
            />
          </TimeSelectionRow>
        </TimePickerItem>
        <TimePickerItem>
          <span>
            <Translate id="flowPlanner.to" />
          </span>
          <TimeSelectionRow>
            <StyledTimePicker
              mapFilter
              value={
                valueToChanged
                  ? valueTo
                  : {
                      label: <TextSpan> - : - </TextSpan>,
                      value: null,
                    }
              }
              options={valueFromChanged ? timeOptionsАvailableTo : []}
              selectValue={(newToTime) =>
                setToValue(newToTime, valueFrom, true)
              }
            />
          </TimeSelectionRow>
        </TimePickerItem>
      </TimePickers>
      <ResetContainer>
        <ResetButton onClick={resetInteval}>
          <ResetIcon name={icons.RESET} />
          <Translate id="billboard.mainPanel.timeReset" />
        </ResetButton>
      </ResetContainer>
    </>
  )
}

TimePickerFilter.propTypes = {
  cancel: PropTypes.func,
  selectedDate: PropTypes.string,
  buildingId: PropTypes.number,
  type: PropTypes.string,
  defaultStart: PropTypes.string,
  defaultEnd: PropTypes.string,
  currentFloorId: PropTypes.number,
  timeFormat: PropTypes.string,
}

const mapStateToProps = (state) => ({
  defaultStart: getDefaultStart(state),
  defaultEnd: getDefaultEnd(state),
  hasClaimOnBehalfPermission: getHasClaimOnBehalfPermission(state),
  currentFloorId: getCurrentFloorId(state),
  timeFormat: getTimeFormat(state),
})

export default connect(mapStateToProps, {})(TimePickerFilter)
